import React from "react";

import ConfirmationModal from "../ConfirmationModal";
import { Portal } from "../Modal";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import useParticipantInfo from "../../hooks/useParticipantInfo";

const AddToSessionModal = ({ title, onConfirm, onClose, participant }) => {
  const { displayName } = useParticipantInfo(participant.participantId);
  const [addToSessionText, confirmAddText] = useCopy([
    "sessioncontrols.waitingroom.addToSession",
    "sessioncontrols.waitingroom.confirmAdd",
  ]);

  return (
    <Portal>
      <ConfirmationModal
        title={addToSessionText}
        onConfirm={onConfirm}
        onClose={onClose}
      >
        {swapCopyVariables(confirmAddText, {
          NAME: displayName,
        })}
      </ConfirmationModal>
    </Portal>
  );
};
export default AddToSessionModal;
