import React, { useEffect, useRef } from "react";
import styled, { css, useTheme } from "styled-components";
import { H2, CloseButton } from "@sussex/react-kit/elements";

const FixedWrapper = styled.div`
  width: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(84, 94, 110, 0.4);
  z-index: 11;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

const Centered = styled.div`
  height: 100%;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 2px 2px 10px 0px ${({ theme }) => theme.colors.shadow};
  overflow: hidden;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: auto;
    width: calc(100% - 60px);
    max-height: 75vh;
    margin: 0 10px;
    overflow: hidden;
    border-radius: 16px;
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "auto")};
  }
`;

const Header = styled.div`
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.primaryBlue};
  display: flex;
  align-items: center;
  ${({ headerPosition }) =>
    headerPosition === "left"
      ? css`
          justify-content: flex-start;
        `
      : css`
          justify-content: center;
        `}
  color: white;
  fill: white;
  padding: 24px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 32px;
    border-bottom: 0;
  }
  ${({ variant }) => variant.header}
`;

const Body = styled.div`
  padding: 0 16px;
  overflow-y: auto;
  flex: 1;
  text-align: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 32px;
  }
`;

const Footer = styled.div`
  padding: 16px 16px 16px;
  background: white;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 32px;
    border-top: 0;
    position: static;
    bottom: auto;
  }
`;

const variants = theme => ({
  blue: {
    header: `
      background: ${theme.colors.primaryBlue};
      color: ${theme.colors.white};
      fill: ${theme.colors.white};
    `,
    closeButton: {
      circle: false,
      size: "18px",
      fill: theme.colors.white,
    },
  },
  light: {
    header: `
      background: ${theme.colors.white};
      color: ${theme.colors.default};
    `,
    closeButton: {
      circle: false,
      size: "18px",
      fill: theme.colors.default,
    },
  },
  grey: {
    header: `
      background: ${theme.colors.light};
      color: ${theme.colors.default};
      padding: 20px;
    `,
    closeButton: {
      circle: false,
      size: "18px",
      fill: theme.colors.default,
    },
  },
});

const CloseWrapper = styled.div`
  position: absolute;
  right: 16px;
`;

export default function Modal({
  footer,
  title,
  children,
  close,
  variant = "light",
  maxWidth = "900px",
  headerPosition = "center",
}) {
  const modalWrapper = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const clickOffClose = e => {
      if (!close) {
        return;
      }
      if (e.target === modalWrapper.current) {
        close();
      }
    };
    document.body.style.overflow = "hidden";
    window.addEventListener("click", clickOffClose);
    return () => {
      document.body.style.overflow = "";
      window.removeEventListener("click", clickOffClose);
    };
  }, [close]);

  const v = variants(theme)[variant];

  return (
    <FixedWrapper>
      <Centered ref={modalWrapper}>
        <Wrapper maxWidth={maxWidth}>
          {title && (
            <Header headerPosition={headerPosition} variant={v}>
              <H2>{title}</H2>
              {close && (
                <CloseWrapper>
                  <CloseButton
                    width={v.closeButton.size}
                    height={v.closeButton.size}
                    fill={v.closeButton.fill}
                    circle={v.closeButton.circle}
                    close={close}
                    id="modalHeaderCloseButton"
                  />
                </CloseWrapper>
              )}
            </Header>
          )}
          <Body>{children}</Body>
          {footer && <Footer>{footer}</Footer>}
        </Wrapper>
      </Centered>
    </FixedWrapper>
  );
}
