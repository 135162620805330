import React from "react";
import { datadogEvent } from "../../datadog";
import useCopy from "../../hooks/useCopy";
import useVideoContext from "../../hooks/useVideoContext";
import ConfirmationModal from "../ConfirmationModal";
import { requestEndSession } from "../../api/httpapi";
import { useAppStateContext, actions } from "../../providers/AppStateProvider";

const { SESSION_END } = actions;

export default function ExitConfirmation({ isTherapist, onClose }) {
  const { dispatch } = useAppStateContext();
  const { room } = useVideoContext();

  const [
    confirmEndSessionText,
    confirmEndSessionTextClient,
    confirmEndSessionTitle,
  ] = useCopy([
    "callscreen.confirmEndSession",
    "callscreen.confirmEndSessionClient",
    "callscreen.confirmEndSessionTitle",
  ]);

  const endSessionTherapist = async () => {
    datadogEvent({
      category: "session",
      feature: "end",
      event: "clicked",
      component: "VideoSession.ExitConfirmation",
    });

    const ended = await requestEndSession();

    if (ended) {
      datadogEvent({
        category: "session",
        event: "ended",
        component: "VideoSession.ExitConfirmation",
      });
    }
  };

  const endSessionClient = () => {
    dispatch({ type: SESSION_END });
    room.disconnect();
  };

  return (
    <ConfirmationModal
      title={confirmEndSessionTitle}
      onConfirm={isTherapist ? endSessionTherapist : endSessionClient}
      onClose={onClose}
      variant="compact"
    >
      {isTherapist ? confirmEndSessionText : confirmEndSessionTextClient}
    </ConfirmationModal>
  );
}
