import React from "react";
import styled from "styled-components";
import Panel from "../Panel";
import closeIcon from "../../assets/icon-close.svg";

const Wrapper = styled.div``;

const Content = styled.div`
  width: 100%;
  display: ${({ hasActions }) => (hasActions ? "block" : "flex")};
  align-items: center;
  @media (min-width: 720px) {
    display: flex;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const ActionsWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 16px;
  @media (min-width: 720px) {
    margin-top: 0;
    margin-left: 0;
  }
`;

const Text = styled.div`
  margin: 0 16px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.fonts.semiBold};
`;

const Message = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

const CloseBtn = styled.div`
  width: 10px;
  height: 10px;
  background: url(${closeIcon});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  content: " ";
`;

const Banner = ({ icon, message, title, onClose, actions: Actions }) => {
  return (
    <Wrapper>
      <Panel>
        <Content hasActions={typeof Actions !== "undefined"}>
          <MessageWrapper>
            <img src={icon} alt="" />
            <Text>
              {title ? <Title>{title}</Title> : null}
              <Message>{message}</Message>
            </Text>
          </MessageWrapper>
          {Actions && (
            <ActionsWrapper>
              <Actions />
            </ActionsWrapper>
          )}

          {onClose && (
            <div>
              <CloseBtn onClick={onClose} />
            </div>
          )}
        </Content>
      </Panel>
    </Wrapper>
  );
};

export default Banner;
