import React, {
  createContext,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import chime from "../../assets/chime.mp3";
import { useDevicesContext } from "../../providers/DevicesProvider";
import EventBus from "../../eventBus";

const AudioAlertContext = createContext();

export function AudioAlertProvider({ children }) {
  let audioOutputDevice = null;
  try {
    const devicesContext = useDevicesContext();
    audioOutputDevice = devicesContext?.audioOutputDevice;
  } catch (e) {
    // This is fine, just means that the AudioAlertProvider has no access to the DevicesProvider
  }

  const bellChimeRef = useRef(new Audio(chime));
  const chimeOnNewParticipants = useRef(false);

  const playChime = useCallback(() => {
    if (bellChimeRef.current.play) {
      bellChimeRef.current.muted = false;
      const p = bellChimeRef.current.play();
      if (p) {
        p.catch(() => {});
      }
    }
  }, []);

  useEffect(() => {
    // memoize it
    const handler = () => {
      let triggered = false;
      return () => {
        if (!triggered && bellChimeRef.current.play) {
          bellChimeRef.current.muted = true;
          const p = bellChimeRef.current.play();
          if (p) {
            p.catch(() => {});
          }
        }
        triggered = true;
      };
    };

    document.addEventListener("touchstart", handler());
    document.addEventListener("mousedown", handler());

    return () => {
      document.removeEventListener("touchstart", handler());
      document.removeEventListener("mousedown", handler());
    };
  }, []);

  useEffect(() => {
    if (!audioOutputDevice) {
      return;
    }
    if (bellChimeRef.current.setSinkId) {
      bellChimeRef.current
        .setSinkId(audioOutputDevice.deviceId)
        .catch(() => {});
    }
  }, [audioOutputDevice]);

  const setChimeOnNewParticipants = useCallback(val => {
    chimeOnNewParticipants.current = val;
  }, []);

  const onNewParticipant = useCallback(() => {
    if (chimeOnNewParticipants.current) {
      playChime();
    }
  }, [playChime]);

  useEffect(() => {
    EventBus.on("newParticipantJoined", onNewParticipant);

    return () => {
      EventBus.off("newParticipantJoined", onNewParticipant);
    };
  }, [onNewParticipant]);

  return (
    <AudioAlertContext.Provider
      value={{ playChime, setChimeOnNewParticipants }}
    >
      {children}
    </AudioAlertContext.Provider>
  );
}

export function useAudioAlertContext() {
  const context = useContext(AudioAlertContext);
  if (!context) {
    throw new Error(
      "useAudioAlertContext must be used within a AudioAlertContext",
    );
  }

  return context;
}
