import React, { createContext, useEffect, useContext, useState } from "react";
import { useAppStateContext } from "../../providers/AppStateProvider";
import EventBus from "../../eventBus";

const NewParticipantsContext = createContext();

export function NewParticipantsProvider({ children }) {
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants: waitingParticipants } = waitingRoom;
  const [seenParticipants, setSeenParticipants] = useState([]);

  // this ref tracks participants as "seen" when they show up to a room
  useEffect(() => {
    const newUnseenParticipants = waitingParticipants.filter(
      p =>
        !seenParticipants.map(s => s.participantId).includes(p.participantId),
    );
    if (newUnseenParticipants.length > 0) {
      setSeenParticipants(prev => prev.concat(newUnseenParticipants));
      EventBus.emit("newParticipantJoined");
    }
  }, [waitingParticipants, seenParticipants]);

  return (
    <NewParticipantsContext.Provider value={{ seenParticipants }}>
      {children}
    </NewParticipantsContext.Provider>
  );
}

export function useNewParticipantsContext() {
  const context = useContext(NewParticipantsContext);
  if (!context) {
    throw new Error(
      "useNewParticipantsContext must be used within a NewParticipantsContext",
    );
  }

  return context;
}
