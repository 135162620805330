import React, { useState, useRef, useCallback } from "react";
import styled, { css } from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import muteIcon from "../../assets/icon-mute-client.svg";
import unmuteIcon from "../../assets/icon-unmute-client.svg";
import endCallIcon from "../../assets/icon-remove.svg";
import pinIcon from "../../assets/pin-icon.svg";
import unpinIcon from "../../assets/unpin-icon.svg";
import useCopy from "../../hooks/useCopy";
import ConfirmationModal from "../ConfirmationModal";
import useWindowSize from "../../hooks/useWindowSize";

const Wrapper = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  background-color: ${({ theme }) => theme.colors.transparentGreyBackground};
  border-radius: 8px;
  padding: 5px 8px;
  height: 30px;
  text-align: center;
  border: none;
  flex-direction: column;

  ${({ isMain }) =>
    !isMain &&
    css`
      display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
      position: absolute;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;
    `}

  backdrop-filter: blur(4px);
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    ${({ isMain }) =>
      !isMain &&
      css`
        border-radius: 0;
        height: 100%;
      `}
  }
`;

const ControlItem = styled.button`
  background-color: transparent;
  border: none;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  color: white;
  cursor: pointer;
  text-align: center;
  margin: 1px 0;
  height: 26px;
  outline: none;
  padding: 3px 15px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1px 6px;
    margin: 0 8px;
    background: none;
  }

  img {
    display: block;
    user-select: none;
    -webkit-user-drag: none;
  }

  &:disabled {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;
  }

  &:hover {
    img {
      opacity: 0.75;
      transition: 0.7s;
    }
  }
`;

export default function Controls({
  showAdminControls,
  showPinControls = true,
  isMain = false,
  isVisible,
  isSelected,
  setSelected,
  isAudioEnabled,
  toggleMuteParticipant,
  removeParticipant,
  participantId,
  displayName,
}) {
  const [
    muteText,
    unmuteText,
    removeText,
    pinText,
    unpinText,
    removeClientConfirmationTitle,
    removeClientConfirmationBody,
  ] = useCopy([
    "callscreen.mute",
    "callscreen.unmute",
    "callscreen.remove",
    "callscreen.pin",
    "callscreen.unpin",
    "callscreen.removeClientConfirmationTitle",
    "callscreen.removeClientConfirmationBody",
  ]);
  const { width } = useWindowSize();
  const controlsRef = useRef(null);
  const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const makeBusy = fn => {
    return async () => {
      setBusy(true);
      await fn();
      if (controlsRef.current) {
        setBusy(false);
      }
    };
  };

  const showControls = isVisible && (showAdminControls || showPinControls);

  const IconButton = useCallback(
    ({ tooltip, ...rest }) => {
      if (!tooltip || !showControls || width < 768) {
        return <ControlItem {...rest} />;
      }

      return (
        <Tooltip title={tooltip}>
          <ControlItem {...rest} />
        </Tooltip>
      );
    },
    [width, showControls],
  );
  return (
    <>
      <Wrapper isMain={isMain} isVisible={showControls} ref={controlsRef}>
        {showPinControls && (
          <IconButton
            onClick={setSelected}
            id={`participantControlPin-${participantId}`}
            tooltip={isSelected ? unpinText : pinText}
          >
            <img src={isSelected ? unpinIcon : pinIcon} alt={pinText} />
          </IconButton>
        )}
        {showAdminControls && (
          <>
            <IconButton
              onClick={makeBusy(toggleMuteParticipant)}
              disabled={busy}
              id={`participantControlMute-${participantId}`}
              tooltip={isAudioEnabled ? muteText : unmuteText}
            >
              <img
                src={isAudioEnabled ? muteIcon : unmuteIcon}
                alt={isAudioEnabled ? muteText : unmuteText}
              />
            </IconButton>
            <IconButton
              onClick={() => setRemoveConfirmationOpen(true)}
              disabled={busy}
              id={`participantControlRemove-${participantId}`}
              tooltip={removeText}
            >
              <img src={endCallIcon} alt={removeText} />
            </IconButton>
          </>
        )}
      </Wrapper>
      {removeConfirmationOpen && (
        <ConfirmationModal
          title={removeClientConfirmationTitle}
          onConfirm={e => {
            makeBusy(removeParticipant)();
            setRemoveConfirmationOpen(false);
          }}
          onClose={() => {
            setRemoveConfirmationOpen(false);
          }}
        >
          {removeClientConfirmationBody.replace("[CLIENT_NAME]", displayName)}
        </ConfirmationModal>
      )}
    </>
  );
}
