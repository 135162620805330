import React, { useEffect } from "react";
import successImg from "../../assets/invite-sent-success.svg";
import failImg from "../../assets/fail.svg";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import useCopy from "../../hooks/useCopy";
import { useAnalyticsContext } from "../../providers/AnalyticsProvider";
import Banner from "../Banner";

const InviteSentNotification = ({ result, name }) => {
  const { analyticsEvent } = useAnalyticsContext();
  const [successText, failText, successTitle] = useCopy([
    "invite.successMessage",
    "invite.failMessage",
    "invite.successTitle",
  ]);

  useEffect(() => {
    if (result) {
      analyticsEvent({
        eventName: "banner",
        actionType: "show",
        actionName: "invite-sent",
      });
    }
  }, [result, analyticsEvent]);

  if (result == null) {
    return null;
  }

  const icon = result === "success" ? successImg : failImg;
  const messageText =
    result === "success"
      ? swapCopyVariables(successText, { NAME: name })
      : failText;
  const titleText = result === "success" ? successTitle : null;

  return <Banner icon={icon} message={messageText} title={titleText} />;
};

export default InviteSentNotification;
