import React from "react";
import styled, { css } from "styled-components";
import ParticipantControls from "../ParticipantControls";
import Participant from "../Participant";
import ParticipantInfo from "../ParticipantInfo";
import usePublications from "../../hooks/usePublications";
import useTrack from "../../hooks/useTrack";
import useIsTrackEnabled from "../../hooks/useIsTrackEnabled";
import useVideoContext from "../../hooks/useVideoContext";
import useParticipantInfo from "../../hooks/useParticipantInfo";
import useHoverOrTouch from "../../hooks/useHoverOrTouch";

const topOffsetMobile = 154;
const bottomOffsetMobile = 90;

const topOffsetTablet = 188;
const bottomOffsetTablet = 97;
const bottomOffsetTabletLandscape = 73;

const totalOffsetMobile = topOffsetMobile + bottomOffsetMobile;
const totalOffsetTablet = topOffsetTablet + bottomOffsetTablet;
const totalOffsetTabletLandscape =
  topOffsetTablet + bottomOffsetTabletLandscape;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: calc(100% - ${totalOffsetMobile}px);
  width: calc(100% - 32px);
  align-self: center;
  margin: 16px;
  margin-top: ${topOffsetMobile}px;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: calc(100% - ${totalOffsetTablet}px);
    @media (orientation: landscape) {
      height: calc(100% - ${totalOffsetTabletLandscape}px);
    }
    flex-direction: row;
    margin-top: ${topOffsetTablet}px;
  }
`;

const GridItem = styled.div`
  border-radius: 16px;
  overflow: hidden;
  height: ${({ count }) => 100 / count}%;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: 16px;
    &:nth-child(1) {
      margin-left: 0;
    }
    width: ${({ count }) => 100 / count}%;
    height: 100%;
  }
`;

const ParticipantWrap = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const InfoWrap = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  ${({ hideParticipantInfo }) =>
    hideParticipantInfo
      ? css`
          display: none;
        `
      : css`
          display: flex;
        `};
`;

const Item = ({
  sendMessage,
  setSelectedParticipant,
  isTherapist,
  participant,
  count,
  key,
}) => {
  const { displayName } = useParticipantInfo(participant.identity);
  const [hoverRef, isHovering] = useHoverOrTouch();

  const pinParticipant = () => {
    setSelectedParticipant(participant);
  };

  const removeParticipant = () => {
    sendMessage({
      type: "kick",
      to: participant.identity,
    });
  };

  const toggleMuteParticipant = () => {
    const message = isAudioEnabled ? "mute" : "unmute";

    sendMessage({
      type: message,
      to: participant.identity,
    });
  };

  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === "audio");
  const audioTrack = useTrack(audioPublication);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);
  return (
    <GridItem count={count} key={key}>
      <ParticipantWrap ref={hoverRef}>
        <Participant
          participant={participant}
          showAdminControls={isTherapist}
          isMain
        >
          <InfoWrap>
            <ParticipantInfo participant={participant} />
            <ParticipantControls
              isVisible={isHovering}
              showAdminControls={isTherapist}
              isSelected={false}
              setSelected={pinParticipant}
              isAudioEnabled={isAudioEnabled}
              toggleMuteParticipant={toggleMuteParticipant}
              showPinControls={true}
              removeParticipant={removeParticipant}
              identity={participant.identity}
              displayName={displayName}
              isMain
            />
          </InfoWrap>
        </Participant>
      </ParticipantWrap>
    </GridItem>
  );
};
export default function ParticipantList({ isTherapist }) {
  const { participants, setSelectedParticipant, sendMessage } =
    useVideoContext();

  return (
    <Wrapper>
      {participants.map(participant => (
        <Item
          isTherapist={isTherapist}
          setSelectedParticipant={setSelectedParticipant}
          count={participants.length}
          key={participant.sid}
          participant={participant}
          sendMessage={sendMessage}
        />
      ))}
    </Wrapper>
  );
}
