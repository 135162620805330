import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import ChatBubble from "./ChatBubble";
import useWindowSize from "../../hooks/useWindowSize";

const ScrollWrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  padding: 0 20px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const BubbleWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: ${({ isSender }) => (isSender ? "flex-end" : "flex-start")};
`;

const ChatLog = ({ messages, isTherapist, participant, inSession }) => {
  const chatRef = useRef(null);
  const scrollRef = useRef(null);
  const { height } = useWindowSize();

  useEffect(() => {
    if (!chatRef.current || !scrollRef.current) return;
    scrollRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [messages.length, height]);

  // Prevent touch devices from scrolling body after scrolling beyond container.
  // This addresses an iOS-specific issue.
  useEffect(() => {
    const chatLog = scrollRef.current;
    if (!chatLog) return;

    const handleTouchScroll = e => {
      const target = e.currentTarget;
      if (!target) {
        return;
      }

      const child = target.children[0];
      if (child.clientHeight < target.clientHeight) {
        e.preventDefault();
        return;
      }

      if (target.scrollTop === 0) {
        target.scrollTop = 1;
      } else if (
        target.scrollHeight ===
        target.scrollTop + target.offsetHeight
      ) {
        target.scrollTop -= 1;
      }
    };

    chatLog.addEventListener("touchmove", handleTouchScroll, {
      passive: false,
    });
    return () => {
      chatLog.removeEventListener("touchmove", handleTouchScroll, {
        passive: false,
      });
    };
  }, []);

  if (isTherapist && !participant) {
    return null;
  }

  return (
    <ScrollWrapper ref={scrollRef}>
      <Wrapper ref={chatRef}>
        {messages.map(m => (
          <BubbleWrapper
            isSender={m.self}
            key={`${m.from}-${m.timestamp.getTime()}`}
          >
            <ChatBubble
              isSender={m.self}
              from={m.from}
              message={m.message}
              inSession={inSession}
            />
          </BubbleWrapper>
        ))}
      </Wrapper>
    </ScrollWrapper>
  );
};

export default ChatLog;
