import React, { useState } from "react";
import styled from "styled-components";
import { TextInput, Checkbox } from "@sussex/react-kit/elements";
import { validateEmail } from "@sussex/react-kit/utils";
import Flex from "../Elements/Flex";
import DayAndTimeDropdown from "../DayAndTimeDropdown";
import MessageArea from "./MessageArea";
import getCopy from "./InviteToSessionCopy.js";
import { swapCopyVariables } from "@sussex/react-kit/utils";
import Footer from "./Footer";
import { requestSendInvitation } from "../../api/httpapi";
import { useWaitingRoomContext } from "../../providers/WaitingRoomProvider";
import Modal from "../Modal";
import {
  SESSIONS_APP_URL_IOS,
  SESSIONS_APP_URL_ANDROID,
} from "../../mobileApps";

const Spacer = styled.div`
  &:first-child {
    margin-top: 16px;
  }
  margin-bottom: 20px;
`;

const Label = styled.span`
  margin-left: 10px;
  font-size: 14px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 17px;
  }
`;

const isValidName = name => name.length > 2;

const nextIncrement = () => {
  const date = new Date();
  const quarterHour = Math.ceil(date.getMinutes() / 15);
  const hour = date.getHours();
  return new Date(
    new Date().setHours(
      hour + (quarterHour === 4 ? 1 : 0),
      (quarterHour % 4) * 15,
      0,
      0,
    ),
  );
};

function getExtension(path) {
  const name = path.split("/").pop();
  const ext = name.split(".").pop();
  return ext === name ? "" : ext;
}

const InviteToSessionModal = ({ close }) => {
  const [
    title,
    nameLabel,
    emailLabel,
    startTimeLabel,
    noStartTimeText,
    messageTitle,
    messagePlaceholder,
    buttonText,
    sendingText,
    defaultMessage,
    noStartTimeMessage,
    senderText,
    copyLinkText,
    copiedToClipboardText,
    missingNameText,
    invalidEmailText,
    customMessageTitle,
    defaultSubject,
    noStartTimeSubject,
    mobileAppText,
    iosAltText,
    androidAltText,
  ] = getCopy();

  const { room, getRoomUrl } = useWaitingRoomContext();

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState(nextIncrement());
  const [sending, setSending] = useState(false);
  const [noDateAndTime, setNoDateAndTime] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const messagePrefix = noDateAndTime ? noStartTimeMessage : defaultMessage;
  const appointmentDate = selectedDate?.toLocaleString([navigator.language], {
    weekday: "long",
    day: "numeric",
    month: "long",
  });
  const appointmentTime = selectedDate?.toLocaleString([navigator.language], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const getSender = () => {
    const vars = {
      THERAPIST_NAME: room.therapistDisplayName,
    };

    return swapCopyVariables(senderText, vars).join("");
  };

  const getSubject = () => {
    const subjectText = noDateAndTime ? noStartTimeSubject : defaultSubject;
    const vars = {
      THERAPIST_NAME: room.therapistDisplayName,
    };

    if (!noDateAndTime) {
      vars.APPOINTMENT_DATE = appointmentDate;
      vars.APPOINTMENT_TIME = appointmentTime;
    }

    return swapCopyVariables(subjectText, vars).join("");
  };

  const getMessageContent = message => {
    const vars = {
      ROOM_URL: getRoomUrl(),
      THERAPIST_NAME: room.therapistDisplayName,
      CLIENT_NAME: clientName,
    };

    if (!noDateAndTime) {
      vars.APPOINTMENT_DATE = appointmentDate;
      vars.APPOINTMENT_TIME = appointmentTime;
    }

    return swapCopyVariables(message, vars).join("");
  };

  const getCustomMessageContent = () => {
    if (customMessage.trim() === "") {
      return "";
    }

    const vars = {
      THERAPIST_NAME: room.therapistDisplayName,
    };

    const title = swapCopyVariables(customMessageTitle, vars).join("");
    const content = customMessage.replace(/(?:\r\n|\r|\n)/g, "<br>");

    return `<b>${title}</b><br><br>${content}`;
  };

  const getMessageHTML = message => {
    const messageContent = getMessageContent(message);
    const customContent = getCustomMessageContent();
    let customMessageHtml = "";

    if (customContent !== "") {
      customMessageHtml = `
        <div style="padding: 15px; margin: 25px 0 10px 0; background-color: #F5F7F9; border: 1px solid #DDDDDD; font-size: 13px;">
          ${customContent}
        </div>
      `;
    }

    const env = process.env.REACT_APP_ENVIRONMENT;
    const appUrl = `https://${
      env === "prod" ? "" : env + "."
    }sessions.psychologytoday.com`;
    const logoUrl = `${appUrl}/pt-sessions-logo-email.png`;
    let photoUrl = room.therapistPhotoUrl;

    if (getExtension(photoUrl) === "") {
      // workaround for making the photo urls without
      // extension work with the gmail image proxy
      photoUrl += ".jpg";
    }

    const html = `
      <style>
        @import url("https://fonts.googleapis.com/css?family=Open+Sans");
      </style>
      <div style="width: 100%; display: inline-block; font-family: Open Sans, Arial, sans-serif; background-color: #F5F7F9;">
        <div style="width: 600px; max-width: 90%; border: 1px solid #DDDDDD; background-color: #FFFFFF; padding: 15px; margin: 15px auto 0 auto; display: block;">
          <div style="display: table; width: 100%; border-bottom: 1px solid #DDDDDD;">
            <div style="display: table-cell; width: 70px; vertical-align: middle; padding-bottom: 10px;">
              <img src="${photoUrl}" alt="" style="width: 60px; height: 60px; border-radius: 50%; object-fit: cover;">
            </div>
            <div style="display: table-cell; font-size: 15px; font-weight: 600; vertical-align: middle; padding-bottom: 10px;">
              ${room.therapistDisplayName}
            </div>
          </div>
          <div style="margin-top: 20px; line-height: 25px; font-size: 15px;">
            ${messageContent}
          </div>
          <div style="margin-top: 20px; line-height: 25px; font-size: 15px;">
            ${mobileAppText}
            <div style="margin-top: 20px">
              <a href="${SESSIONS_APP_URL_IOS}">
                <img src="${appUrl}/app-link-ios-email.png" alt="${iosAltText}" style="width: 120px;" />
              </a>
            </div>
            <div>
              <a href="${SESSIONS_APP_URL_ANDROID}">
                <img src="${appUrl}/app-link-android-email.png" alt="${androidAltText}" style="width: 120px;" />
              </a>
            </div>
          </div>
          ${customMessageHtml}
        </div>
        <div style="width: 600px; max-width: 90%; text-align: center; padding: 15px; margin: 0 auto 15px auto; display: block;">
          <img src="${logoUrl}" style="width: 150px; margin-top: 15px;">
        </div>
      </div>
    `;

    return html
      .split("\n")
      .map(s => s.trim())
      .join("");
  };

  const submit = async () => {
    setSending(true);
    const sender = getSender();
    const message = getMessageHTML(messagePrefix);
    const subject = getSubject();

    try {
      await requestSendInvitation(
        clientName,
        clientEmail,
        sender,
        message,
        subject,
      );
      setSending(false);
      close(true, clientName);
    } catch (e) {
      console.error("Error Sending Invite", e);
      setSending(false);
      close(false);
    }
  };

  const canSend = isValidName(clientName) && validateEmail(clientEmail);

  const handleNameChange = e => {
    const name = e.target.value;
    setClientName(name);
    // If no current error, just return
    if (!errorName) {
      return;
    }
    // If new name is valid, flip error
    if (isValidName(clientName)) {
      setErrorName(false);
    }
  };

  const handleEmailChange = e => {
    const email = e.target.value;
    setClientEmail(email);
    // If no current error, just return
    if (!errorEmail) {
      return;
    }
    // If new email is valid, flip error
    if (validateEmail(email)) {
      setErrorEmail(false);
    }
  };

  const handleEmailBlur = () => {
    setErrorEmail(!validateEmail(clientEmail));
  };

  const handleNameBlur = () => {
    setErrorName(!isValidName(clientName));
  };

  const closeModal = () => {
    close();
  };

  const toggleNoDateAndTime = () => {
    setNoDateAndTime(!noDateAndTime);
  };

  return (
    <Modal
      maxWidth="900px"
      close={closeModal}
      title={title}
      variant="light"
      headerPosition="left"
      footer={Footer({
        disabled: !canSend || sending,
        buttonText: sending ? sendingText : buttonText,
        send: submit,
        copyLinkText,
        copiedToClipboardText,
      })}
    >
      <Flex basis={["45%", "55%"]} breakAt="1000">
        <div>
          <Spacer>
            <TextInput
              onChange={handleNameChange}
              label={nameLabel}
              error={errorName ? missingNameText : null}
              onBlur={handleNameBlur}
            />
          </Spacer>
          <Spacer>
            <TextInput
              onChange={handleEmailChange}
              label={emailLabel}
              error={errorEmail ? invalidEmailText : null}
              onBlur={handleEmailBlur}
            />
          </Spacer>
          <Spacer>
            <DayAndTimeDropdown
              disabled={noDateAndTime}
              date={selectedDate}
              label={startTimeLabel}
              onChange={setSelectedDate}
            />
          </Spacer>
          <Spacer>
            <Checkbox checked={noDateAndTime} onClick={toggleNoDateAndTime} />
            <Label onClick={toggleNoDateAndTime}>{noStartTimeText}</Label>
          </Spacer>
        </div>
        <div>
          <MessageArea
            placeholder={messagePlaceholder}
            title={messageTitle}
            message={getMessageContent(messagePrefix)}
            onChange={setCustomMessage}
          />
        </div>
      </Flex>
    </Modal>
  );
};

export default InviteToSessionModal;
