import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import backgroundImage from "../../assets/waiting-room-background.png";
import frameImage from "../../assets/waiting-room-frame.png";
import ClampLines from "react-clamp-lines";

const Wrapper = styled.div`
  position: relative;
`;

const BackgroundImg = styled.img`
  width: 100%;
`;

const Content = styled.div`
  position: absolute;
  width: 39%;
  top: 21%;
  right: 17%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const FrameWrapper = styled.div`
  position: relative;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FrameImg = styled.img`
  width: 100%;
  z-index: 1;
`;

const Photo = styled.img`
  position: absolute;
  width: 76%;
`;

const Name = styled.div`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  line-height: 1.3;
  color: white;
  width: 100%;
  font-size: 12px;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 16px;
  }
`;

const RoomText = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 12px;
  line-height: 1.3;
  color: white;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 14px;
  }
`;

const WaitingRoomBackground = ({ photo, name }) => {
  const [title] = useCopy(["waitingRoom.roomTitle"]);

  return (
    <Wrapper>
      <BackgroundImg src={backgroundImage} alt="" />
      <Content>
        <FrameWrapper>
          <Photo src={photo} alt={name} />
          <FrameImg src={frameImage} />
        </FrameWrapper>
        <Name>
          <ClampLines
            text={name}
            buttons={false}
            id="waiting-room-background-name"
            lines={2}
          />
        </Name>
        <RoomText>{title}</RoomText>
      </Content>
    </Wrapper>
  );
};

export default WaitingRoomBackground;
