import React, { useEffect } from "react";
import styled from "styled-components";
import { useAudioAlertContext } from "../../providers/AudioAlertProvider";
import { useAppStateContext } from "../../providers/AppStateProvider";
import ClientItem from "./ClientItem";
import GhostRow from "./GhostRow";

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ItemWrapper = styled.div`
  background: ${({ theme }) => theme.colors.light};
  border-radius: 100px;
  padding: 8px;
  overflow: hidden;
`;

function ClientList({
  chatDisabled,
  startDisabled,
  startSession,
  loadingIdentity,
}) {
  const {
    state: { waitingRoom },
  } = useAppStateContext();
  const { participants: waitingParticipants } = waitingRoom;
  const { setChimeOnNewParticipants } = useAudioAlertContext();

  const isEmpty = waitingParticipants.length === 0;

  const renderListElement = (p, index) => {
    return (
      <ItemWrapper key={p.participantId}>
        <ClientItem
          participant={p}
          onStart={() => startSession(p)}
          startDisabled={startDisabled || isEmpty || loadingIdentity}
          loading={loadingIdentity === p.participantId}
          chatVisible={true}
          chatDisabled={chatDisabled || isEmpty || loadingIdentity}
          index={index}
        />
      </ItemWrapper>
    );
  };

  useEffect(() => {
    setChimeOnNewParticipants(true);
    return () => {
      setChimeOnNewParticipants(false);
    };
  }, [setChimeOnNewParticipants]);

  return (
    <List>
      {isEmpty ? (
        <ItemWrapper>
          <GhostRow />
        </ItemWrapper>
      ) : (
        waitingParticipants.map(renderListElement)
      )}
    </List>
  );
}

export default ClientList;
