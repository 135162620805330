import { useState } from "react";

const useInviteNotificationState = () => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [clientName, setClientName] = useState(null);
  const [inviteResult, setInviteResult] = useState(null);

  const handleNotificationModalClose = (success, clientName) => {
    setShowInviteModal(false);
    // Anything besids true/false, reject
    if (success !== true && success !== false) {
      return;
    }
    const result = success ? "success" : "fail";
    setClientName(clientName);
    setInviteResult(result);
  };

  return {
    handleNotificationModalClose,
    showInviteModal,
    setShowInviteModal,
    inviteResult,
    clientName,
  };
};

export default useInviteNotificationState;
